
import { Component, Vue } from 'vue-property-decorator';
import { inject } from '@/inversify';
import { KEY } from '@/inversify.keys';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import type ProvidersService from '@/modules/providers/providers.service';
import type ClusterPromotionsService from '../../cluster-promotions.service';

@Component({
    components: { CustomSelect },
})
export default class ProvidersPromotion extends Vue {
    @inject(KEY.ClusterPromotionsService) private clusterPromotionsService!: ClusterPromotionsService;
    @inject(KEY.ProvidersService) private providersService!: ProvidersService;

    get provider() {
        return this.clusterPromotionsService.settings.provider;
    }

    set provider(value) {
        this.clusterPromotionsService.settings.provider = value;
    }

    get providerItems(): Item[] {
        const { promotionProviders } = this.providersService.userProviders;
        return this.providersService.toItemsList(promotionProviders);
    }
}
